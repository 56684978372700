import { derived } from 'overmind'
import _ from 'lodash'
import moment from 'moment'

const combineCapacity = 300;
export default {
  combines: {
    /*'123': {
      id: '123',
      machineId: '123',
      name: 'Fat Tire',
      currentBuchels: 100,
      latitude: 41.44908294945402,
      longitude: -84.97350062051355,
      combineCapacity: 250,
    },
    'aaa': {
      id: 'aaa',
      machineId: 'aaa',
      name: 'Duel Tire',
      currentBuchels: 200,
      latitude: 41.44950294945402,
      longitude: -84.97350062051355,
      combineCapacity: 250
    }*/
  },
  grainCarts: {},
  hasCombines: derived(({combines}) => {
    return _.map(combines).length > 0;
  }),
  view: {
    map: {
      lastTouched: null,
      heading: null
    },
    grainCarts: derived(({}, state) => {
      return _.chain(state.grainCarts).map((grainCart, id) => {
        //Populate buchels, percent, and time remaining
        let ret = {id, name: grainCart.name || '?', latitude: grainCart.latitude, longitude: grainCart.longitude, heading: grainCart.heading};
        return ret;
      }).sortBy('name').value()
    }),
    combines: derived(({}, state) => {
      //Convert combine data to output
      return _.chain(state.combines).map((combine, id) => {
        //Populate buchels, percent, and time remaining
        let ret = {id, name: combine.name || '?', latitude: combine.latitude, longitude: combine.longitude, heading: combine.heading};
        ret.buchels = null
        ret.timeRemaining = null
        ret.percentFull = null
        if (combine.currentBuchels != null && combine.combineCapacity != null) {
          ret.buchels = Math.round(combine.currentBuchels)
          ret.percentFull = Math.round((combine.currentBuchels / combine.combineCapacity)*100);
        }
        if (combine.fullAtTime) {
          ret.fullAtTime = combine.fullAtTime;
        }
        return ret;
      }).sortBy('name').value()
    })
  }
}