import _ from 'lodash';
import L from 'leaflet';
import { getRhumbLineBearing } from 'geolib';

let MAP = null;

function cartesianDistance(s, e) {
  var latS_R = s.latitude * (Math.PI / 180);
  var lngS_R = s.longitude * (Math.PI / 180);
  var sx = (Math.cos(latS_R) * Math.cos(lngS_R));
  var sy = (Math.cos(latS_R) * Math.sin(lngS_R));
  var sz = (Math.sin(latS_R));

  var latE_R = e.latitude * (Math.PI / 180);
  var lngE_R = e.longitude * (Math.PI / 180);
  var ex = (Math.cos(latE_R) * Math.cos(lngE_R));
  var ey = (Math.cos(latE_R) * Math.sin(lngE_R));
  var ez = (Math.sin(latE_R));

  //Compute distance between the two vectors
  return 6378137 * Math.sqrt(Math.pow((ex - sx), 2) + Math.pow((ey - sy), 2) + Math.pow((ez - sz), 2));
}

export const onCombineClick = ({ state, actions, effects }, id) => {
  //Send message to combine to clear it's buchels
  effects.grainCart.clearBuchels(id);
  //Pre-clear the buchels
  state.combines[id].currentBuchels = 0;
}
export const onMapCreated = ({ state, actions, effects }, map) => {
  MAP = map;
  if (window.location.href.indexOf('graincart') != -1) {
    setInterval(() => {
      navigator.geolocation.getCurrentPosition((pos) => {
        const {latitude, longitude, accuracy} = pos.coords;
        console.log('Coors', {latitude, longitude, accuracy});
        if (accuracy <= 150) actions.onLocationChange({latitude, longitude, accuracy})
      }, (err) => {
        console.log("Location Error", err);
      }, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 1000
      })
    }, 1000)
  }
}
export const onLocationChange = ({ state, actions, effects }, {latitude, longitude}) => {
  state.view.map.location = {latitude, longitude};
  let sendUpdate = {latitude, longitude};
  if (state.view.map.oldLocation == null) {
    state.view.map.oldLocation = {latitude, longitude}
  } else {
    //Check distance from heading location, if move ~20ft calc heading and reset
    const dist = cartesianDistance(state.view.map.location, state.view.map.oldLocation) * 3.28084;
    if (dist >= 20) {
      const newHeading = getRhumbLineBearing(state.view.map.oldLocation, {latitude, longitude})
      state.view.map.heading = newHeading
      state.view.map.oldLocation = {latitude, longitude}
      sendUpdate['heading'] = state.view.map.heading
    }
  }
  effects.grainCart.send(sendUpdate)
  fitMapToBounds({state});
}
export const onMapTouch = ({ state, actions, effects }, map) => {
  state.view.map.lastTouched = Date.now();
}
export const onGrainCartMessage = ({ state, actions, effects }, message) => {
  if (message.machineId == null) return;
  if (message.type == 'hello') {
    const iAm = _.get(message, 'iAm') || [];
    if (Array.isArray(iAm) && iAm.indexOf('grain-cart') != -1) {
      state.grainCarts[message.machineId] = {
        machineId: message.machineId,
        name: _.get(message, 'meta.name'),
      }
    } else {
      const hadCombines = state.hasCombines;
      state.combines[message.machineId] = {
        machineId: message.machineId,
        name: _.get(message, 'meta.name'),
        combineCapacity: _.get(message, 'meta.combineCapacity')
      }
      effects.grainCart.send({
        to: message.machineId,
        type: 'info'
      })
      if (hadCombines == false) {
        setTimeout(() => {
          if (MAP) MAP.invalidateSize(true);
        }, 300)
      }
    }

  } else if (message.type == 'goodbye') {
    if (state.combines[message.machineId]) {
      delete state.combines[message.machineId];
    } else if (state.grainCarts[message.machineId]) {
      delete state.grainCarts[message.machineId];
    }
  } else {
    //console.log('message', message);
    if (state.combines[message.machineId]) {
      state.combines[message.machineId] = _.merge({}, state.combines[message.machineId] || {}, message);
    } else if (state.grainCarts[message.machineId]) {
      state.grainCarts[message.machineId] = _.merge({}, state.grainCarts[message.machineId] || {}, message);
    }
    if (message.latitude || message.longitude) {
      fitMapToBounds({state});
    }
  }
}
const fitMapToBounds = ({state}) => {
  const lastTouched = state.view.map.lastTouched;
  if (lastTouched != null && (Date.now() - lastTouched < 5000)) return;
  const points = _.chain(state.combines).map(({latitude, longitude}) => {
    if (!latitude || !longitude) return null;
    return L.latLng(latitude, longitude);
  }).concat(_.map(state.grainCarts, ({latitude, longitude}) => {
    if (!latitude || !longitude) return null;
    return L.latLng(latitude, longitude);
  })).compact().value();
  if (state.view.map.location) {
    const {latitude, longitude} = state.view.map.location; 
    points.push(L.latLng(latitude, longitude))
  }
  if (points.length > 0) {
    const aBounds = L.latLngBounds(points);
    if (MAP) MAP.fitBounds(aBounds, {padding: [200, 200], maxZoom: 17});
  }
}

export const onGrainCartClose = ({ state, actions }) => {
  console.log('Connection to server lost.')
}

export const onInitializeOvermind = async ({state, actions, effects}, overmind) => {
  //Connect to grain cart p2pt
  console.log("INIT")
  effects.grainCart.start({onMessage: actions.onGrainCartMessage, onClose: actions.onGrainCartClose});
}