import moment from 'moment'

export default function TimeRemaining(props) {
  let seconds = (props.fullAtTime - (Date.now() / 1000));
  if (seconds < 0) seconds = 0;
  const dur = moment.duration(seconds, 'seconds');
  let text = '';
  if (dur.hours() > 0) {
    text += dur.hours() + ' hr '
  }
  if (dur.minutes() > 0) {
    text += dur.minutes() + ' min '
  }
  if (dur.seconds() > 0) {
    text += dur.seconds() + ' seconds '
  }
  text = text.trim();
  return (
    <div style={{fontSize: 30}}>
      {text}
    </div>
  );
}