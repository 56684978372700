import * as React from "react"
import L from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server';

function SvgComponent(props) {
  const {me, heading, ...otherProps} = props;
  const theHeading = heading == null ? 90 : heading;
  let rotate = 0;
  let flip = '';
  if (theHeading <= 180) {
    rotate = theHeading - 90; //Starts at 90 deg so subtract 90 to get north
  } else {
    rotate = theHeading - 90 - 180;
    flip = ' scale(-1, 1)';
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={2046}
      height={852}
      viewBox="182.5 60.5 2046 852"
      transform={`rotate(${rotate})${flip}`}
      {...otherProps}
    >
      <path
        fill={me ? "#FFF" : "#000"}
        d="M1808.019 852.054c-67.928 0-129.413-35.576-168.21-90.582h-119.715c-16.177 0-32.354-12.955-32.354-29.132h-12.954c-32.351 71.184-103.534 122.968-184.42 122.968-109.98 0-200.564-90.613-200.564-200.596 0-35.608 9.701-67.961 25.875-100.315l-12.95-12.921c-9.702-9.7-12.925-22.654-6.479-35.608 0-3.223 38.83-100.282 174.723-109.982h19.396c42.085 0 77.662 9.7 103.538 19.4v-48.53c0-19.4 12.954-32.354 32.354-32.354h29.132v-97.06c-19.433-9.7-29.132-29.1-29.132-55.007 0-35.576 25.876-61.452 61.482-61.452h304.103c19.433 0 32.354 12.922 32.354 32.353v29.099c0 16.177-12.922 32.354-29.099 32.354v87.361c200.598 9.698 317.057 93.836 320.275 97.059 9.732 6.477 16.178 19.431 12.955 32.353l-32.352 152.068c19.396 9.7 32.352 29.13 32.352 55.007v29.098c0 35.608-25.875 61.484-61.482 61.484h-74.406c-32.356 74.404-103.54 122.935-184.422 122.935z"
      />
      <path
        fill={me ? "#FFF" : "#000"}
        d="M691.406 853.5c-98.119-.12-177.63-79.631-177.75-177.75l.031-2.781-132.625-88.406a31.281 31.281 0 01-13.906-26v-45.656l-78.75-78.75a31.251 31.251 0 01-9.156-22.094V267.531c-16.454-1.071-29.252-14.73-29.25-31.219V177.75c0-17.259 13.991-31.25 31.25-31.25h791c17.259 0 31.25 13.991 31.25 31.25v58.594c-.001 16.506-12.839 30.164-29.312 31.188v144.594c0 8.281-3.281 16.25-9.156 22.094l-78.75 78.75v45.656a31.248 31.248 0 01-8.906 21.844l245.75 35.094c15.389 2.207 26.814 15.392 26.812 30.938v58.594c0 17.259-13.991 31.25-31.25 31.25H858.531c-25.671 70.2-92.377 116.962-167.125 117.154z"
      />
      <path
        fill="#367C2B"
        d="M1218.75 705.062H808.594V587.875L1218.75 646.5v58.562z"
      />
      <path
        opacity={0.3}
        fill="#333"
        d="M1218.75 705.062H808.594V587.875L1218.75 646.5v58.562z"
      />
      <path
        fill="#367C2B"
        d="M1042.969 207.031H310.562v205.094L398.438 500v58.594l175.781 117.188h234.375l146.469-117.188V500l87.906-87.875V207.031z"
      />
      <circle fill="#333" cx={691.406} cy={675.781} r={146.5} />
      <circle fill="#FFDE00" cx={691.406} cy={675.781} r={87.875} />
      <path
        opacity={0.3}
        fill="#333"
        d="M955.062 500H398.438l-87.875-87.875h732.406L955.062 500z"
      />
      <path fill="#FFDE00" d="M310.562 236.312H1043v29.312H310.562v-29.312z" />
      <circle opacity={0.3} fill="#333" cx={691.406} cy={675.781} r={29.312} />
      <path fill="#333" d="M281.25 177.75h791v58.594h-791V177.75z" />
      <path
        fill="#FFF"
        d="M1701.258 457.337h-61.449c0-61.452-61.485-122.936-122.968-122.936v-90.583c0-19.399 12.951-29.13 29.128-29.13h122.938c19.43 0 29.131 12.954 29.131 29.13v213.519h3.22z"
      />
      <path
        fill="#367C2B"
        d="M2066.901 613.949h-61.453V461.884h-575.883v29.13L1277.5 429.531l-152.066 90.613L1277.5 643.082v61.451h242.649v29.131h181.164v-29.131h362.365c19.4 0 29.131-12.922 29.131-29.1v-29.129c3.223-22.654-9.73-32.355-25.908-32.355z"
      />
      <path
        opacity={0.3}
        fill="#333"
        d="M2067.214 614.762h-61.453V462.696h-575.883v29.13l-152.065-61.482-152.066 90.613 152.066 122.938v61.451h242.649v29.131h181.164v-29.131h362.365c19.4 0 29.131-12.922 29.131-29.1v-29.129c3.223-22.654-9.73-32.355-25.908-32.355z"
      />
      <path
        opacity={0.3}
        fill="#333"
        d="M2067.151 615.918h-61.453V463.853h-575.883v29.13L1277.75 431.5l-152.066 90.613 152.066 122.938v61.451h242.649v29.131h181.164v-29.131h362.365c19.4 0 29.131-12.922 29.131-29.1v-29.129c3.223-22.654-9.73-32.355-25.908-32.355z"
      />
      <path
        fill="gray"
        d="M1662.429 282.649l-16.178-9.7-35.573 61.453 16.177 9.7 35.574-61.453z"
      />
      <path fill="gray" d="M1714.212 305.303v119.682l-74.403-119.682h74.403z" />
      <path
        fill="#333"
        d="M1293.621 486.467c92.922 0 168.243 75.323 168.243 168.245 0 92.92-75.321 168.243-168.243 168.243s-168.243-75.323-168.243-168.243c0-92.922 75.321-168.245 168.243-168.245z"
      />
      <path
        fill="#FFDE00"
        d="M1293.621 564.128c50.014 0 90.584 40.568 90.584 90.584 0 50.014-40.57 90.582-90.584 90.582s-90.584-40.568-90.584-90.582c0-50.016 40.57-90.584 90.584-90.584z"
      />
      <path
        opacity={0.3}
        fill="#333"
        d="M1293.621 625.581c16.081 0 29.099 13.048 29.099 29.131 0 16.081-13.018 29.099-29.099 29.099s-29.132-13.018-29.132-29.099c0-16.083 13.051-29.131 29.132-29.131z"
      />
      <path
        fill="#367C2B"
        d="M2053.924 638.535h-19.432s-29.1 0-38.797-29.132c-12.955-45.275-29.132-152.066-232.952-152.066V334.401c213.519 0 333.233 90.583 333.233 90.583l-42.052 213.551z"
      />
      <path
        fill="#FFDE00"
        d="M1762.743 363.533c152.066 0 242.649 29.098 242.649 29.098s-74.406-45.275-242.649-45.275v16.177z"
      />
      <path
        fill="#333"
        d="M2095.977 424.985L2060.4 606.183s-25.908 0-32.354-29.132c-9.731-29.098-35.606-168.243-35.606-168.243l103.537 16.177zm-122.938 90.614l-29.131-29.131-16.174-87.359 16.174 3.254 29.131 113.236zm-45.305-45.307l-25.879-12.955-12.954-67.929 19.433 6.477 19.4 74.407z"
      />
      <path
        fill="#367C2B"
        d="M1791.842 182.334h-333.23c0-19.399 12.951-29.099 29.128-29.099h304.103v29.099z"
      />
      <path
        fill="#333"
        d="M1733.612 182.334h-271.748c0 19.431 12.921 29.13 29.098 29.13h242.65v-29.13z"
      />
      <path
        fill="#333"
        d="M1487.739 182.334v333.264h181.168l90.58-61.484v-271.78h-271.748zm213.519 275.003h-61.449c0-61.452-61.485-122.936-122.968-122.936v-90.583c0-19.399 12.951-29.13 29.128-29.13h122.938c19.43 0 29.131 12.954 29.131 29.13v213.519h3.22z"
      />
      <path
        opacity={0.3}
        fill="#5DC9E8"
        d="M1701.258 457.337h-61.449c0-61.452-61.485-122.936-122.968-122.936v-90.583c0-19.399 12.951-29.13 29.128-29.13h122.938c19.43 0 29.131 12.954 29.131 29.13v213.519h3.22z"
      />
      <path fill="gray" d="M1733.612 272.949h16.177v152.036h-16.177V272.949z" />
      <path
        fill="#367C2B"
        d="M1610.678 486.467v29.131H1487.74v-29.131h-29.128l-29.102-61.482v-61.452h61.452c64.709 0 119.716 55.006 119.716 122.934z"
      />
      <path
        fill="#333"
        d="M1429.417 425.079l61.453-.158.188 61.452-61.486.159-.155-61.453z"
      />
      <path
        opacity={0.3}
        fill="#FFF"
        d="M1610.678 486.467c0-67.928-55.007-122.935-122.938-122.935h-61.482v29.098h61.482c93.806 3.255 122.938 93.837 122.938 93.837z"
      />
      <path
        fill="#333"
        d="M1520.094 547.953h122.938s-29.131 29.098-29.131 119.68v64.707h-90.584V547.953h-3.223z"
      />
      <path
        fill="gray"
        d="M1581.546 667.633h-61.452l-32.354-119.68h61.453l32.353 119.68z"
      />
      <path
        fill="#333"
        d="M1808.019 486.467c92.922 0 168.243 75.323 168.243 168.245 0 92.92-75.321 168.243-168.243 168.243-92.889 0-168.21-75.323-168.21-168.243 0-92.922 75.321-168.245 168.21-168.245z"
      />
      <path
        fill="#FFDE00"
        d="M1808.019 564.128c50.047 0 90.614 40.568 90.614 90.584 0 50.014-40.567 90.582-90.614 90.582-50.014 0-90.584-40.568-90.584-90.582 0-50.016 40.57-90.584 90.584-90.584z"
      />
      <path
        opacity={0.3}
        fill="#333"
        d="M1808.019 625.581c16.114 0 29.132 13.048 29.132 29.131 0 16.081-13.018 29.099-29.132 29.099-16.052 0-29.098-13.018-29.098-29.099 0-16.083 13.046-29.131 29.098-29.131z"
      />
      <path
        fill="#367C2B"
        d="M1293.621 457.337c100.282 0 100.282 61.484 168.243 61.484 0 0-32.354-90.583-168.243-90.583s-168.243 87.361-168.243 87.361c67.928 0 67.928-58.262 168.243-58.262z"
      />
      <path
        fill="#333"
        d="M1610.678 457.337c16.081 0 29.131 13.048 29.131 29.13 0 16.083-13.05 29.131-29.131 29.131-16.082 0-29.132-13.049-29.132-29.131 0-16.081 13.05-29.13 29.132-29.13z"
      />
    </svg>
  )
}

export default function({size, heading, me}) {
  const svgString = encodeURIComponent(renderToStaticMarkup(<SvgComponent heading={heading} me={me} />));
  const dataUri = `data:image/svg+xml,${svgString}`;
  return new L.Icon({
    iconUrl: dataUri,
    iconRetinaUrl: dataUri,
    popupAnchor:  [-0, -0],
    iconSize: [size || 60, size || 60],     
  });
}