import React from 'react';
import { useRef, useEffect } from 'react';

import classnames from 'classnames'
import _ from 'lodash'
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, Tooltip } from 'react-leaflet'
import styles from './index.module.css';
import 'leaflet/dist/leaflet.css';
import './index.css'
import Combine from './icons/combineWithHead';
import { useState, useActions } from '../../overmind'
import GrainCart from './icons/grainCart';

function Map(props) {
  const state = useState();
  const actions = useActions();
  return (
    <MapContainer whenCreated={(map) => {actions.onMapCreated(map)}} center={[41.44908294945402, -84.97350062051355]} zoom={15} scrollWheelZoom={true} zoomControl={false} attributionControl={false} >
      <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      {
        _.map(state.view.combines, ({id, name, buchels, percentFull, latitude, longitude, heading }) => {
          if (latitude == null || longitude == null) return;
          let fill = 0;
          if (percentFull >= 80) {
            fill = 4;
          } else if (percentFull >= 60) {
            fill = 3;
          } else if (percentFull >= 40) {
            fill = 2;
          } else if (percentFull >= 20) {
            fill = 1;
          }
          const combineIcon = Combine({size: 110, fill, heading});
          return (
            <Marker key={id} position={[latitude, longitude]}
              icon={combineIcon}>
                <Tooltip permanent direction="top" offset={[0, -15]} className={[styles.tooltip]}>
                  {percentFull ? percentFull+'%' : ''}
                </Tooltip>
            </Marker>
          )
        })
      }
      {
        _.map(state.view.grainCarts, ({id, name, latitude, longitude, heading }) => {
          if (latitude == null || longitude == null) return;
          return (
            <Marker key={id} position={[latitude, longitude]}
              icon={GrainCart({size: 110, heading})}></Marker>
          )
        })
      }
      {
        state.view.map.location == null ? null :
        <Marker key={'mylocation'} position={[state.view.map.location.latitude, state.view.map.location.longitude]}
              icon={GrainCart({size: 110, me: true, heading: state.view.map.heading})}></Marker>
      }
      <ZoomControl position="topright" />
    </MapContainer>
  );
}
export default Map;
