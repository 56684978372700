import * as React from "react"
import L from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server';

function SvgComponent(props) {
  let heading = props.heading == null ? 90 : (props.heading + 180);
  heading = heading % 360;
  let rotate = 0;
  let flip = '';
  if (heading <= 190 || heading >= 350) {
    rotate = heading - 90; //Starts at 90 deg so subtract 90 to get north
  } else {
    flip = 'scale(1, -1) translate(0,-100) '; //if flip, change to different svg 
    rotate = -1*heading+90;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} {...props}>
      <g transform={`${flip}rotate(${rotate} 50 50)`}>
        <path fill={props.me ? "#FFF" : "#000"} d="M47.063 67.57a7.987 7.987 0 01-7.679-5.804H31.88a5.856 5.856 0 01-5.849 5.804 5.855 5.855 0 01-5.849-5.849c0-1.438.526-2.802 1.452-3.856L19.2 54.177l-2.542-1.271a1.497 1.497 0 01-.828-1.338c0-2.628.516-5.162 1.105-7.204H16.5l-1.011 1.011a1.497 1.497 0 01-1.059.438h-1.45a1.497 1.497 0 01-1.421-1.971l1.45-4.351a1.497 1.497 0 011.42-1.023l15.574-.002-2.316-3.472a1.498 1.498 0 011.246-2.327h23.205a1.496 1.496 0 011.246 2.327l-1.348 2.022h7.355c.826 0 1.496.671 1.496 1.498v2.903c0 .203-.041.404-.121.59l-4.35 10.153a1.495 1.495 0 01-1.289.904l5.035 3.021c.451.271.727.757.727 1.283v2.901c0 .827-.67 1.498-1.496 1.498h-4.65a7.989 7.989 0 01-7.68 5.803z" />
        <path
          fill="#367C2B"
          d="M49.214 39.985v2.895l2.417 7.238.479 1.447v1.448l7.236 4.342v2.895H25.04l-4.777-7.236-2.896-1.448c0-3.344.883-6.514 1.665-8.685.652-1.78 1.231-2.895 1.231-2.895h11.58v-1.449h27.503v1.449H49.214z"
        />
        <path
          fill="#FFF"
          d="M55.004 51.565h-2.896l-2.893-8.685v-1.447h10.131l-4.342 10.132z"
        />
        <path
          fill="gray"
          d="M58.188 44.327l-2.605 5.79-1.26-4.56-.477.797-.869-.436 1.736-2.895.869.436-.521.868h3.127z"
        />
        <path
          opacity={0.3}
          fill="#5DC9E8"
          d="M55.004 51.565h-2.896l-2.893-8.685v-1.447h10.131l-4.342 10.132z"
        />
        <path
          fill="#367C2B"
          d="M14.474 39.985h15.921v2.895H14.474v-2.895zm37.633 10.132v1.448l-.477-1.448h.477zm0-15.922h-23.16l2.897 4.343h17.37l2.893-4.343z"
        />
        <path
          opacity={0.3}
          fill="#333"
          d="M20.264 39.985s-.579 1.115-1.231 2.895h-4.559v-2.895h5.79z"
        />
        <path fill="#367C2B" d="M52.107 50.117v1.448l-.477-1.448h.477z" />
        <path
          fill="#333"
          d="M51.631 50.118h3.949l-.576 1.447h-2.896l-.477-1.447z"
        />
        <circle fill="#333" cx={47.042} cy={59.526} r={6.514} />
        <path
          fill="#FFDE00"
          d="M18.091 45.775c9.698 0 22.581 1.304 31.121 2.896 0 0-22.436-1.449-31.411-1.449l.29-1.447z"
        />
        <path
          opacity={0.3}
          fill="#333"
          d="M59.346 57.355v2.895H25.04l-4.777-7.236s0-1.737 3.763-1.737c1.968 0 4.791 2.128 7.817 3.503V38.537l-2.895-4.341h23.16l-2.893 4.341H32.566v3.619h16.646v.725H32.566v12.202c1.202.493 2.418.825 3.619.825 4.487 0 4.053-4.342 8.685-4.342h7.239v1.448l7.237 4.341z"
        />
        <circle fill="#FFDE00" cx={47.042} cy={59.526} r={3.619} />
        <circle opacity={0.3} fill="#333" cx={47.042} cy={59.526} r={2.171} />
        <circle fill="#333" cx={26.054} cy={61.698} r={4.342} />
        <circle fill="#FFDE00" cx={26.054} cy={61.698} r={1.448} />
        <path
          fill="#333"
          d="M13.025 44.329h1.449l1.447-1.448-1.447-2.895-1.449 4.343zm36.189-4.344h10.131v1.448H49.214v-1.448z"
        />
        <path d="M64.705 67.57c-1.711 0-2.906-1.264-2.906-3.07v-1.639l-1.689-.229a1.852 1.852 0 01-1.592-1.824v-7.383c0-2.133 1.73-3.804 3.941-3.804.74 0 1.471.199 2.111.575l.92.585 2.293-4.012a1.845 1.845 0 011.598-.928h7.383c.658 0 1.27.354 1.598.927l3.281 5.742a1.839 1.839 0 010 1.827l-.506.886a1.8 1.8 0 01.285.26l6.561 7.383a1.83 1.83 0 01.201 2.176 1.854 1.854 0 01-2.035.828l-6.229-1.557a1.827 1.827 0 01-1.762.779l-1-.137-.387.774c-.68 1.357-2.197 1.839-3.289 1.839h-8.777v.002z" />
        <g>
          <path
            fill="#367C2B"
            d="M80.498 56.706h-7.016v4.386l10.178 2.5 3.746.921-6.908-7.807z"
          />
          <path
            fill="#FFDE00"
            d="M84.006 60.652l3.506 3.945-3.506-.877v-3.068z"
          />
          <path
            fill="#367C2B"
            d="M73.482 66.352H64.1c-.701 0-1.139-.438-1.139-1.314v-4.823l14.029 1.754-1.754 3.507c-.439.876-1.754.876-1.754.876z"
          />
          <path
            opacity={0.3}
            fill="#333"
            d="M73.482 66.352H64.1c-.701 0-1.139-.438-1.139-1.314v-4.823l13.152 1.754-.879 2.63-1.752 1.753z"
          />
          <path
            fill="#333"
            d="M69.098 59.336l-3.508-6.137 3.508-6.139h7.893l3.508 6.139-3.508 6.137h-7.893z"
          />
          <path
            opacity={0.3}
            fill="#FFF"
            d="M74.709 53.9c-.525-.439-.525-1.053 0-1.491l1.58-1.227c.525-.439 1.139-.263 1.314.351 0 0 .176.525.176 1.578 0 1.055-.176 1.58-.176 1.58-.176.613-.789.789-1.314.35l-1.58-1.141zm-3.33 0c.525-.439.525-1.053 0-1.491l-1.58-1.227c-.525-.439-1.139-.264-1.314.351 0 0-.176.525-.176 1.578 0 1.055.176 1.58.176 1.58.176.613.789.789 1.314.35l1.58-1.141zm.963.965c.439-.526 1.053-.526 1.492 0l1.227 1.578c.439.525.264 1.141-.352 1.315 0 0-.525.176-1.578.176s-1.578-.176-1.578-.176c-.613-.176-.791-.79-.352-1.315l1.141-1.578zm0-3.332c.439.525 1.053.525 1.492 0l1.227-1.579c.352-.613.176-1.14-.439-1.403 0 0-.525-.176-1.578-.176s-1.578.176-1.578.176c-.611.263-.787.79-.438 1.315l1.314 1.667z"
          />
          <path
            fill="#367C2B"
            d="M78.457 63.502L59.453 61.09v-7.893c0-1.752 2.016-2.629 3.508-1.752l9.645 6.138 5.851 5.919z"
          />
          <path
            opacity={0.3}
            fill="#333"
            d="M70.852 56.706h-1.754l-1.752 4.384h1.752l1.754-4.384z"
          />
          <circle fill="#FFDE00" cx={62.521} cy={54.514} r={1.315} />
          <path
            fill="#FFDE00"
            d="M76.289 63.371l-1.053 2.104c-.439.877-1.754.877-1.754.877H68.22v-.877h6.139V61.09l1.93 2.281z"
          />
          <path
            fill="#FFDE00"
            d="M74.359 63.107v-3.771l4.385 4.385-4.385-.614z"
          />
        </g>
        {
          props.fill != 1 ? null : <path fill="#FFDE00" d="M32.087 32.668h17.125l-8.851-1.376" />
        }
        {
          props.fill != 2 ? null : <path fill="#FFDE00" d="M32.087 32.668h17.125l-8.877-2.76" />
        }
        {
          props.fill != 3 ? null : <path fill="#FFDE00" d="M32.087 32.668h17.125l-8.851-4.71" />
        }
        {
          props.fill != 4 ? null : <path fill="#FFDE00" d="M32.087 32.668h17.125l-8.851-6.711" />
        }
      </g>
    </svg>
  )
}

export default function({size, fill, heading, me}) {
  const svgString = encodeURIComponent(renderToStaticMarkup(<SvgComponent fill={fill} heading={heading} me={me} />));
  const dataUri = `data:image/svg+xml,${svgString}`;
  return new L.Icon({
    iconUrl: dataUri,
    iconRetinaUrl: dataUri,
    popupAnchor:  [-0, -0],
    iconSize: [size || 60, size || 60],     
  });
}

