import { createOvermind } from 'overmind'
import { Provider } from 'overmind-react'
import { config } from './overmind'
import Main from './view'

const overmind = createOvermind(config, {
  devtools: 'localhost:3031'
})

export default function App(props) {
  return (
    <Provider value={overmind}>
      <Main />
    </Provider>
  );
}