const WebSocket = require('isomorphic-ws');
const Promise = require('bluebird');
const EventEmitter = require('events');
const bjson = require('buffer-json-encoding')
const PASSWORD = '0sr1W5UKz0SowIVqUrcjg2yc0xrPauLTshp4BImZ1nBknZ4cbXUFW'

class Client extends EventEmitter {
  constructor({machineId, room, iAm, interestedIn, meta, reconnectInterval, autoConnect}) {
    super();
    this.machineId = machineId;
    this.room = room;
    this.iAm = iAm;
    this.interestedIn = interestedIn;
    this.meta = meta;
    this.ws = null;
    this.connected = false;
    this.terminated = false;
    this.sendQueue = [];
    this.reconnectInterval = reconnectInterval || 5000;
    if (autoConnect !== false) this.connect();
  }
  reconnect() {
    if (this.terminated) return;
    return this.connect()
  }
  connect() {
    if (this.connected) return;
    console.log('Connecting...');
    this.terminated = false;
    if (process.env.REACT_APP_LOCALHOST) {
      this.ws = new WebSocket('ws://localhost:8080');
    } else {
      this.ws = new WebSocket('wss://bowman.farm:8080');
    }
    this.ws.binaryType = "arraybuffer";
    this.ws.onerror = (err) => {
      this.emit('err', err);
    }
    this.ws.onopen = () => {
      console.log('Connected to server.')
      this.connected = true;
      this.ws.send(bjson.encode({
        machineId: this.machineId,
        password: PASSWORD,
        room: this.room,
        iAm: this.iAm,
        interestedIn: this.interestedIn,
        meta: this.meta
      }));
      this.emit('open')
      if (this.sendQueue.length > 0) {
        const qLength = this.sendQueue.length;
        for (let i=0; i<qLength; i++) {
          const m = this.sendQueue.shift();
          try {
            this.ws.send(m.message)
            m.resolve();
          } catch (err) {
            //Put back in queue
            console.warn('Failed to send queue message', err);
            this.sendQueue.push(m);
          }
        }
      }
    }
    this.ws.onmessage = (message) => {
      try {
        let TYPED_ARRAY = new Uint8Array(message.data);
        const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
        const json = bjson.decode(STRING_CHAR);
        this.emit('message', json);
        console.log('json', json)
      } catch (err) {
        console.warn('Unable to decode message.');
      }
    }
    this.ws.onclose = () => {
      this.connected = false;
      this.emit('close');
      if (this.terminated == false) {
        return Promise.delay(this.reconnectInterval).then(() => {
          this.reconnect(); //Auto reconnect
        })
      }
    }
    return Promise.resolve();
  }
  send(message, guarantee) {
    try {
      message = bjson.encode(message);
    } catch (err) {
      console.warn('Unable to encode message.');
      return Promise.reject('Unable to encode message.');
    }
    try {
      if (this.connected == false) throw 'Not connected'
      this.ws.send(message)
      return Promise.resolve();
    } catch (err) {
      if (guarantee !== false) {
        return new Promise((resolve, reject) => {
          console.log('adding message to send queue');
          this.sendQueue.push({resolve, reject, message});
        })
      }
    }
  }
  terminate() {
    this.terminated = true;
    this.ws.terminate();
  }
}
export default Client;