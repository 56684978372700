import wsClient from '../../lib/wsClient'
import { v4 as uuidv4 } from 'uuid'
let connection = null;
let newHeading = 90;
function _start({onMessage, onClose}) {
  let machineId = null;
  if (localStorage.getItem('machineId')) {
    machineId = localStorage.getItem('machineId');
  } else {
    machineId = uuidv4();
    localStorage.setItem('machineId', machineId);
  }
  connection = new wsClient({
    machineId: machineId,
    room: 'bowman-farms',
    iAm: ['grain-cart'],
    interestedIn: ['combine', 'grain-cart'],
    meta: {
      name: 'Grain Cart'
    },
    reconnectInterval: 1000
  })
  connection.on('message', (message) => {
    onMessage(message);
  })
  connection.on('close', () => {
    console.log('connection closed by server')
    onClose()
  })
  /*setInterval(() => {
    newHeading = (newHeading + 1) % 360;
    onMessage({
      machineId: '123',
      heading: newHeading
    });
  }, 100);*/
}
function _send(message) {
  connection.send(message)
}

export default {
  start: _start,
  send: _send
}