import _ from 'lodash';
import styles from './App.module.css';
import { useState, useActions } from '../overmind'
import Map from './Map'
import TimeRemaining from './TimeRemaining';
export default function Main(props) {
  const state = useState();
  const actions = useActions();

  return (
    <div className={styles.App}>
      <div className={state.hasCombines ? styles.right : styles.full} onTouchStart={actions.onMapTouch}>
        <Map />
      </div>
      {
        state.view.combines.length > 0 ? null :
        <div className={styles.full} style={{zIndex: 1000, alignItems: 'center', justifyContent: 'center', pointerEvents: 'none'}}>
          <div style={{
            fontSize: 40,
            color: '#fff',
            textShadow: '0px 0px 5px #000',
            paddingBottom: 200,
            }}>{'Searching for combines...'}</div>
        </div>
      }
      <div className={styles.left}>
      {
        _.map(state.view.combines, ({id, name, buchels, percentFull, fullAtTime}) => {
          let color = null;
          if (percentFull == null) {
            color = '#D1D1D1'
          } else if (percentFull >= 90) {
            color = '#FF2727'
          } else if (percentFull >= 70) {
            color = '#F5EC00'
          } else {
            color = '#00CC2D'
          }
          return (
            <div key={id} className={styles.combine} style={{backgroundColor: color}} onClick={() => actions.onCombineClick(id)}>
              <div>{name}</div>
              <div>
                <div>{buchels != null ? buchels + ' bu' : ''}</div>
                <div style={{fontSize: 50}}>{percentFull != null ? percentFull + '%' : ''}</div>
                <TimeRemaining fullAtTime={fullAtTime} />
              </div>
            </div>
          )
        })
      }
      </div>
    </div>
  );
}